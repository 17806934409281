import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import jwt, { decode } from "jsonwebtoken";
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles({
    root: {
        maxWidth: 280,
    },
    media: {
        height: 140,
    },
    cardmedia: {
        width: "120px", height: "120px", margin: "auto"
    },
});
const  handlePageRedirect = () => {
    window.location.href = 'https://finehh.pakistanautomation.com.pk/home'
  }
 
export default function Cards() {
    const [userRole, setUserRole] = useState(0);
    useEffect(() => {
        var token = localStorage.getItem("token");
        var decoded = jwt.decode(token, { complete: true });
        console.log(decoded)
        const isAdmin = decoded.payload.defectRole;
        console.log(isAdmin)
        setUserRole(isAdmin);

    }, []);
    const history = useHistory()
    const classes = useStyles();
    return (
        <div style={{ height: "80vh" }}>
            <div className="centerHeading"><h1 >DEFECT HANDLING SYSTEM</h1> </div>
            <div className='breadcrumb' onClick={handlePageRedirect}>
          <div>
            <img src='/icon/home1.png' alt='homeLogo' ></img>
          </div>
          <div>
            Home
          </div>
        </div>
            <div className="card-container">
                <div>
                    <Link to="/Dashboard/Defacts" style={{ textDecoration: "none" }}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.cardmedia}
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    image="/icon/health-check.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text" className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                        <span className="textSize">Report Defect</span>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                    </Link>
                </div>
                {userRole == 'Admin' 
                    ?
                    <>
                        <div>
                            <Link to="/Dashboard/DHGraph" style={{ textDecoration: "none" }}>
                                <Card className={classes.root}>
                                    <CardActionArea>
                                        <CardMedia
                                            className={classes.cardmedia}
                                            component="img"
                                            alt="PAKAUTO"
                                            height="140"
                                            width="200"
                                            image="/icon/home.png"
                                            title="PakAuto Logo"
                                        />
                                        <CardContent className="card-text">
                                            <Typography gutterBottom variant="h4" component="h4" align="center">
                                                <span className="textSize">Defect Dashboard</span>

                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>

                                </Card>
                            </Link>
                        </div>

                        <div>
                            <Link to="/Dashboard/Admin" style={{ textDecoration: "none" }}>
                                <Card className={classes.root}>
                                    <CardActionArea>
                                        <CardMedia
                                            className={classes.cardmedia}
                                            component="img"
                                            alt="PAKAUTO"
                                            height="140"
                                            width="200"
                                            image="/icon/unauthorized-person.png"
                                            title="PakAuto Logo"
                                        />
                                        <CardContent className="card-text">
                                            <Typography gutterBottom variant="h4" component="h4" align="center">
                                                <span className="textSize">Admin</span>
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>
                        </div>
                    </>
                    :
                    null
                }
            </div>
        </div>
    )
}
