import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
//Dialog Imports
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
// test icons
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const useStyles = makeStyles((theme) => ({
  modal: {
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sizeInc: {
    width: "120%",
  },
  buttonSubmit: {
    //    marginTop:'150px'
  },
  paper: {
    height: "100%",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "3",
    float: "right",
  },
}));
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export default function SystemNames() {
  const [validated, setValidated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const columns = [
    // {
    //   field: "id",
    //   title: "ID",
    //   cellStyle: {
    //     backgroundColor: "#039be5",
    //     color: "#FFF",
    //   },
    //   headerStyle: {
    //     backgroundColor: "#039be5",
    //   },
    // },

    { field: "systemName", title: "System Names" },
  ];

  //mehak edit start
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openR, setOpenR] = React.useState(false);
  const handleOpenR = () => {
    setOpenR(true);
  };

  const handleCloseR = () => {
    setOpenR(false);
  };
  //mehak edit end

  const classes = useStyles();
  // States

  // system data state
  const [SystemNames, setSystemNames] = useState([]);
  // Form state
  const [systemName, setSystemName] = useState("");

  const SystemData = async () => {
    const fetchedData = await axios.get(
      "https://defectbe.pakistanautomation.com.pk/api/systems/getSystems"
    );
    setSystemNames(fetchedData.data.systemName);
    console.log(fetchedData.data);
    // setLoading(false)
  };

  useEffect(() => {
    SystemData();
  }, []);

  //for clearing form
  const clearForm = () => {
    setSystemName("");
  };

  console.log(SystemNames);
  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      // add validation form
      setIsDisabled(false);
      toast.error("Please add system name");
      return;
    }
    if (systemName.length === 0) {
      // alert("please write area name")
      toast.error("Please add system name");
      e.preventDefault();
      e.stopPropagation();
      setIsDisabled(false);
      setValidated(true);

      return;
    }
    setValidated(true);
    setIsDisabled(true);

    console.log({
      systemName: systemName,
    });
    axios
      .post("https://defectbe.pakistanautomation.com.pk/api/systems/insertSystemName", {
        // code:code,
        systemName: systemName,
      })
      .then((res) => {
        console.log(res);
        setIsDisabled(false);
        clearForm();
        if (res.data.results === "Success") {
          toast.success("Inserted successfully !");
          setIsDisabled(false);
          setOpenR(false);
          SystemData();
          setValidated(false);
          SystemData();

        }
      }).catch((error)=>{
        // error from catch
        console.log("error from catch ==>", error);
        if(error.message==="Network Error"){
          console.log("i am here");
          toast.error("Server Error!");
          setIsDisabled(false)
          return;

        }
      
        else if (error.response.status === 409) {
          toast.error("System Already Exist");
          setIsDisabled(false);
          return;
        } 
        else {
          toast.error("Error !");
          return
        }
       
      })
  };
  return (
    <div className="w-90  m-0 p-0 DHContainer">
      <div>
        <Button
          style={{ float: "right" }}
          type="button"
          onClick={handleOpenR}
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add System
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={openR}
          onClose={handleCloseR}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              background: "linear-gradient(to bottom, #039BE5, #22B2F4)",
              width: "600px",
            },
          }}
          className="dialog-mobile-view"
        >
          <DialogTitle id="responsive-dialog-title">
            <Typography
              component="h1"
              variant="h5"
              className="typography text-center text-white border-bottom rounded"
            >
              Add System Name
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Form
              noValidate
              validated={validated}
              className="mobile-view-form"
              onSubmit={submitHandler}
            >
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom03"
                >
                  <Form.Label>Add System Name:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="System Name"
                    className="w-100 mobile-view-text-field"
                    value={systemName}
                    onChange={(e) => setSystemName(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid system name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="test-TwoBtn">
                <div>
                  {isDisabled ? (
                    <Button type="submit" className="dialog-btn" disabled>
                      Save
                    </Button>
                  ) : (
                    <Button type="submit" className="dialog-btn">
                      Save
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    className="dialog-btn close-btn-abc"
                    onClick={() => handleCloseR()}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Form>
          </DialogContent>
        </Dialog>
        {/* Form Ended */}
      </div>
      <div className="w-100">
        <MaterialTable
          icons={tableIcons}
          style={{
            marginTop: "10px",
            whiteSpace: "nowrap",
            // maxWidth: "150%",
            tableLayout: "fixed",
          }}
          title="Systems"
          columns={columns}
          data={SystemNames}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...SystemNames];
                  const index = oldData.id;

                  // console.log(index)
                  dataUpdate[index] = newData;
                  axios
                    .post(
                      "https://defectbe.pakistanautomation.com.pk/api/systems/updateSystemName",
                      newData
                    )
                    .then((res) => {
                      if (res.data.results === "Success") {
                        console.log("updatedSuccessfully");
                        console.log(newData);
                        SystemData();
                        toast.success("Update successfully !");
                      // } else {
                      //   console.log(res.data.results);
                      //   console.log(newData);
                      //   toast.error("Error !");
                      }
                    }).catch((error)=>{
                      // error from catch
                      console.log("error from catch ==>", error);
                      if(error.message==="Network Error"){
                        console.log("i am here");
                        toast.error("Server Error!");
                        setIsDisabled(false)
                        return;
              
                      }
                     
                      else if (error.response.status === 409) {
                        toast.error("System already Exist");
                        setIsDisabled(false);
                        return;
                      } 
                      else {
                        toast.error("Error !");
                        return
                      }
                     
                    })
                  // setCauseData([...dataUpdate]);
                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const index = oldData.id;
                  axios
                    .post(
                      "https://defectbe.pakistanautomation.com.pk/api/systems/deleteSystemName",
                      {
                        id: index,
                      }
                    )
                    .then((res) => {
                      if (res.data.results === "Success") {
                        console.log("updatedSuccessfully");
                        // console.log(newData);
                        SystemData();
                        toast.info("Delete successfully !");
                      } else {
                        console.log(res.data.results);
                        // console.log(newData);
                        toast.error("Error !");
                      }
                    }).catch((error)=>{
                      // error from catch
                      console.log("error from catch ==>", error);
                      if(error.message==="Network Error"){
                        console.log("i am here");
                        toast.error("Server Error!");
                        setIsDisabled(false)
                        return;
              
                      }
                     
                      
                      else {
                        toast.error("Error !");
                        return
                      }
                     
                    })

                  resolve();
                }, 1000);
              }),
          }}
          options={{
            filtering: true,
            exportButton: true,
            // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],

            headerStyle: {
              backgroundColor: "#2E3B55",
              color: "white",
            },
          }}
        />
      </div>
    </div>
  );
}
