import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import jwt from "jsonwebtoken";
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles({
    root: {
        maxWidth: 240,
        maxHeight: 200,
    },
    media: {
        height: 140,
    },
    cardmedia: {
        width: "120px", height: "120px", margin: "auto"
    },
    main: {
        height: "100vh",

    }
});


export const AdminCards = () => {
    const [userRole, setUserRole] = useState(0);

    useEffect(() => {
        var token = localStorage.getItem("token");
        var decoded = jwt.decode(token, { complete: true });
        const isAdmin = decoded.payload.isAdmin;
        setUserRole(isAdmin);

    }, []);

    const classes = useStyles();
    return (
        <div className={classes.main}>
            <div className="card-container">

                <div className="hvr-grow-shadow">
                    <Link to="/DashBoard/department" style={{ textDecoration: "none" }}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.cardmedia}
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    width="200"
                                    image="/icon/department.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                        <span className="textSize">AREA</span>

                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </div>
                <div className="hvr-grow-shadow">
                    <Link to="/DashBoard/machineType" style={{ textDecoration: "none" }}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.cardmedia}
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    width="200"
                                    image="/icon/conveyor.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                        <span className="textSize">Machine</span>

                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </div>
                <div className="hvr-grow-shadow">
                    <Link to="/DashBoard/DefectPart" style={{ textDecoration: "none" }}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.cardmedia}
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    width="200"
                                    image="/icon/relief-valve.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                        <span className="textSize">Parts</span>

                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </div>
                <div className="hvr-grow-shadow">
                    <Link to="/DashBoard/System" style={{ textDecoration: "none" }}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.cardmedia}
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    width="200"
                                    image="/icon/innovation.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                        <span className="textSize">System</span>

                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </div>
                <div className="hvr-grow-shadow">
                    <Link to="/DashBoard/DefectType" style={{ textDecoration: "none" }}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.cardmedia}
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    width="200"
                                    image="/icon/defectType.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                        <span className="textSize">Defect Type</span>

                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </div>
               
                <div className="hvr-grow-shadow">
                    <Link to="/DashBoard/UsersPriority" style={{ textDecoration: "none" }}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.cardmedia}
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    width="200"
                                    image="/icon/message.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                        <span className="textSize">Users Priority</span>

                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </div>
                {/* <div className="hvr-grow-shadow">
                    <Link to="/DashBoard/UserManage" style={{ textDecoration: "none" }}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.cardmedia}
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    width="200"
                                    image="/icon/management.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                        <span className="textSize">User Management</span>

                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </div> */}
            </div>
        </div>
    )
}
