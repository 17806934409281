import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
//icons
import InboxIcon from "@material-ui/icons/MoveToInbox";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
// import MailIcon from '@material-ui/icons/Mail';
// import AccessAlarm, { SportsRugbySharp } from '@material-ui/icons';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import DashboardIcon from "@material-ui/icons/Dashboard";
// import EqualizerIcon from '@material-ui/icons/Equalizer';
//list
// toast
import { toast } from "react-toastify";
//icon
import logout from "./icon/logout.png";
import logo from "./icon/logo.png";

import {
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import DefactsHandling from "./DefectHandling";
import DefectHandlingDashboard from "./DefectHandlingDashboard";
import Cards from "./Cards";
import UserManage from "./UserManage";
// import SignIn from '../SignIn';
import jwt from "jsonwebtoken";
import ADashBoard from "./AnalyticsDashboard";
import Department from "./Department";
import MachineType from "./MachineType";
import Lines from "./Lines";
import { AdminCards } from "./AdminCards";
import SystemNames from "./SystemNames";
import DefectTypes from "./DefectTypes";
import DefectParts from "./DefectParts";
import UsersPriority from "./UsersPriority";
import { ForDelete } from "./Toast";
import axios from "axios";

const drawerWidth = 380;

const useStyles = makeStyles((theme) => ({
  // rootTest: {
  //     flexGrow: 1,
  // },
  menuButtonTest: {
    marginRight: theme.spacing(2),
    // marginLeft: theme.spacing(2),
    // padding: theme.spacing(4),
  },
  titleTest: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
    height: "fit-content",
  },
  appBar: {
    // background: '#2E3B55',
    // background: 'red',
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
    boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // background: '#1B2530',
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 1,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1, 1),
    background: "#F0F2F5",
    width: "90%",
    padding: "1% 2%",
  },
  test: {
    flexGrow: 1,
  },
}));

export default function MiniDrawer(props) {
  const [userRole, setUserRole] = useState(0);
  const match = useRouteMatch();
  const history = useHistory();
  console.log(props);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [listOpen1, setListOpen1] = React.useState(false);
  const [listOpen2, setListOpen2] = React.useState(false);
  const [listOpen3, setListOpen3] = React.useState(false);
  const [listOpen4, setListOpen4] = React.useState(false);
  const [newPass, setNewPass] = React.useState("");
  const [username, setUserName] = React.useState("");
  const [userId, setUserId] = useState([]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleListClick1 = () => {
    setListOpen1(!listOpen1);
  };
  const handleListClick2 = () => {
    setListOpen2(!listOpen2);
  };
  const handleListClick3 = () => {
    setListOpen3(!listOpen3);
  };
  const handleListClick4 = () => {
    setListOpen4(!listOpen4);
  };
  const goBack = () => {
    history.goBack();
  };
  const goForward = () => {
    history.goForward();
  };
  function decoder() {
    var token = localStorage.getItem("token");
    var decoded = jwt.decode(token, { complete: true });

    if (decoded == null) {
      history.push("/signin");
    } else {
      console.log(decoded.payload);
      localStorage.setItem("username", decoded.payload.username);
      var user = localStorage.getItem("username");
      return user;
    }
  }

  const logoutHandle = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    if (localStorage.getItem("username")) {
      console.log("here");
    } else {
      window.location.href = "https://finehh.pakistanautomation.com.pk/signin";
    }
  };

  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt.decode(token, { complete: true });
    const isAdmin = decoded.payload.isAdmin;
    setUserRole(isAdmin);
    const userId = decoded.payload.id;
    console.log(userId);
    setUserId(userId);
    decoder();
  }, []);

  const ChangePassword = async () => {
    let sendData = await axios.post(
      "https://defectbe.pakistanautomation.com.pk/api/password/updatePassword",
      {
        userId: userId,
        newPass: newPass,
      }
    );
    console.log(sendData);
    console.log(sendData.data.message);
    if (sendData.data.message) {
      toast.success(sendData.data.message);
      setNewPass("");
    } else {
      toast.error("Reset Error");
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.rootTest}>
        <AppBar
          position="fixed"
          className={`for-mobile-resp ${clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}`}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={`menu-icon ${clsx(classes.menuButton, {
                [classes.hide]: open,
              })}`}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.titleTest}>
              {/* <div className='resp-navbar row align-items-center'> */}
              <div className="resp-navbar row align-items-center">
                <div className="col-sm-2  navigation-view ">
                  <div className="d-flex justify-content-center">
                    <div>
                      <Button onClick={goBack}>
                        <ArrowBackIos style={{ fill: "white" }} />
                      </Button>
                    </div>
                    <div>
                      <Button onClick={goForward}>
                        <ArrowForwardIos style={{ fill: "white" }} />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="resp-child-element col-sm-7 navigation-text">
                  <Link
                    to="/Dashboard"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <img src={logo} alt="logo" width="100px" />
                  </Link>
                </div>
                <div className="resp-child-element col-sm-3 ">
                  <div className="d-flex justify-content-end align-items-center">
                    <Typography className="resp-font-size">
                      {decoder().toUpperCase()}
                    </Typography>
                    <Button onClick={logoutHandle} color="inherit">
                      <span className="logoText1">
                        <img src={logout} alt="logout" width="25px" />
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Typography>

          </Toolbar>
        </AppBar>
      </div>
      <div className="drawer">
        <Drawer
          // temporary
          variant="permanent"
          open={open}
          // open={open}
          onEscapeKeyDown={handleDrawerClose}
          onBackdropClick={handleDrawerClose}
          //  variant="temporary" open={open} onEscapeKeyDown={handleDrawerClose} onBackdropClick={handleDrawerClose}
          // style={{backgroundColor:'black'}}

          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <List component="div" disablePadding>
              {/* first list items */}


              <Link
                to={match.url + "/DHGraph"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <img
                      src="/icon/Waste1.png"
                      width="25px"
                      height="23px"
                    ></img>
                  </ListItemIcon>
                  <ListItemText secondary="Defect Dashboard" />
                </ListItem>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to={match.url + "/Defacts"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <img
                      src="/icon/administrator.png"
                      width="25px"
                      height="23px"
                    ></img>
                  </ListItemIcon>
                  <ListItemText secondary="Defacts Handling" />
                </ListItem>
              </Link>

            </List>
           
            {userRole == "Admin" ? (
              <Link
                to={match.url + "/UserManage"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <img
                      src="/icon/management.png"
                      width="25px"
                      height="23px"
                    ></img>
                  </ListItemIcon>
                  <ListItemText secondary="User Management" />
                </ListItem>
              </Link>
            ) : null}
          </List>
        </Drawer>
      </div>
      <main className={`${classes.content}`}>
        <div className={`resp-view ${classes.toolbar}`} />

        {/* defining All routes here */}
        <Switch>
          <Route exact path="/DASHBOARD">
            <Cards />
          </Route>
          <Route path={match.url + "/CARDS"} component={Cards} />

          <Route path={match.url + "/Defacts"} component={DefactsHandling} />
          <Route
            path={match.url + "/DHGraph"}
            component={DefectHandlingDashboard}
          />

          <Route
            path={match.url + "/AnalysisDashboard"}
            component={ADashBoard}
          />
          <Route
            exact
            path={match.url + "/UserManage"}
            component={UserManage}
          />
          <Route
            exact
            path={match.url + "/department"}
            component={Department}
          />
          <Route
            exact
            path={match.url + "/machineType"}
            component={MachineType}
          />
          <Route exact path={match.url + "/Admin"} component={AdminCards} />
          <Route exact path={match.url + "/System"} component={SystemNames} />
          <Route
            exact
            path={match.url + "/DefectType"}
            component={DefectTypes}
          />
          <Route
            exact
            path={match.url + "/DefectPart"}
            component={DefectParts}
          />
          <Route
            exact
            path={match.url + "/UsersPriority"}
            component={UsersPriority}
          />
          <Route exact path={match.url + "/testToast"} component={ForDelete} />
          {/* <Route  exact   path={match.url + "/lines"} component={Lines} /> */}
        </Switch>
      </main>
    </div>
  );
}
