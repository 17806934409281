import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt from "jsonwebtoken";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TextField from "@material-ui/core/TextField";

import MaterialTable from "material-table";
// import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
// import Container from "@material-ui/core/Container";

// Dialof Import
import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { Form, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
// test icons
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

// const rows = [
//   { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
//   { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
//   { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
// ];

const useStyles = makeStyles((theme) => ({
  modal: {
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sizeInc: {
    width: "120%",
  },
  buttonSubmit: {
    //    marginTop:'150px'
  },
  paper: {
    height: "100%",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "3",
    float: "right",
  },
}));
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function UserManage() {
  const [validated, setValidated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const columns = [
    // {
    //   field: "id",
    //   title: "ID",
    //   editable: "never",
    //   cellStyle: {
    //     backgroundColor: "#039be5",
    //     color: "#FFF",
    //   },
    //   headerStyle: {
    //     backgroundColor: "#039be5",
    //   },
    // },
    { field: "username", title: "UserName" },
    { field: "email", title: "Email" },
    {
      field: "isAdmin",
      title: "Admin?",
      lookup: { Admin: "Admin", SuperUser: "SuperUser", User: "User" },
    },
  ];
  //mehak edit start
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openR, setOpenR] = React.useState(false);
  const handleOpenR = () => {
    setOpenR(true);
  };

  const handleCloseR = () => {
    setOpenR(false);
  };
  //mehak edit end

  const classes = useStyles();

  const [userData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState();

  const [userRole, setUserRole] = useState(0);

  const clearForm = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setAdmin("");
  };

  const skuData = async () => {
    const fetchedData = await axios.get(
      "https://defectbe.pakistanautomation.com.pk/api/users/getUsers"
    );

    // let customeData = fetchedData.data.map((item) => {
    //   if (item.isAdmin == 0) {
    //     item.isAdmin = "User";
    //   }
    //   else if(item.isAdmin == 2){
    //     item.isAdmin = "Super-User";
    //   }
    //   else {
    //     item.isAdmin = "Admin";
    //   }
    //   return item;
    // });
    // console.log(customeData);
    setUsersData(fetchedData.data);

    setLoading(false);
  };
  console.log(userRole);
  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt.decode(token, { complete: true });
    const isAdmin = decoded.payload.isAdmin;
    setUserRole(isAdmin);
    skuData();
  }, []);

  console.log(userData);
  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      // add validation form
      setIsDisabled(false);
      setValidated(true);
      toast.error("Please fill all fields")
      return
    }
    // if (
    //   username.length === 0 &&
    //   email.length === 0 &&
    //   password.length === 0 &&
    //   admin === 3
    // ) {
    //   // alert("please write area name")
    //   e.preventDefault();
    //   e.stopPropagation();
    //   setValidated(true);
    //   setIsDisabled(false);

    //   return;
    // }
    setValidated(true);
    setIsDisabled(true);

    console.log({
      email: email,
      username: username,
      password: password,
      isAdmin: admin,
    });
    axios
      .post(
        "https://defectbe.pakistanautomation.com.pk/api/users/userCreation",
        {
          email: email,
          username: username,
          password: password,
          isAdmin: admin,
        }
      )
      .then((res) => {
        console.log("respooooo", res);
        toast.success("Inserted successfully !");
        setIsDisabled(false);
        if (res.data.results === "Success") {
          clearForm();
          setOpenR(false);
          skuData();
          setValidated(false);
          console.log("respooooo", res);
        } else {
          setOpenR(true);
          console.log("respooooo", res);
          toast.error("Error !");
        }
      })
      .catch((error)=>{
        // error from catch
        console.log("error from catch ==>", error);
        if(error.message==="Network Error"){
          console.log("i am here");
          toast.error("Server Error!");
          setIsDisabled(false)
          return;

        }
       
        else if (error.response.status === 409) {
          toast.error("User already Exist");
          setIsDisabled(false);
          return;
        } 
        else {
          toast.error("Error !");
          return
        }
       
      })
  };
  return (
    <div className="w-90  m-0 p-0 DHContainer">
      <div>
        <Button
          style={{ float: "right" }}
          type="button"
          onClick={handleOpenR}
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Users
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={openR}
          onClose={handleCloseR}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              background: "linear-gradient(to bottom, #039BE5, #22B2F4)",
              width: "600px",
            },
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            <Typography
              component="h1"
              variant="h5"
              className="typography text-center text-white border-bottom rounded"
              style={{
                color: "black",
                fontWeight: "bolder",
                fontFamily: "ariel",
              }}
            >
              Create User
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Form noValidate validated={validated} onSubmit={submitHandler}>
              <Row className="mb-3">
                {/* <Form.Group as={Col} md="8" className='w-100' controlId="validationCustom03"> */}
                {/* <Form.Label>Name :</Form.Label> */}
                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom01"
                >
                  <Form.Label>Name :</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    className="w-100"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom02"
                >
                  <Form.Label>Email :</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="email"
                    placeholder="Email"
                    className="w-100"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom03"
                >
                  <Form.Label>Password :</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="password"
                    placeholder="password"
                    className="w-100"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid password.
                  </Form.Control.Feedback>
                </Form.Group>
                <div>
                  <FormControl
                    required
                    className={`w-100 ${classes.formControl}`}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className="text-dark"
                    >
                      Admin?
                    </InputLabel>
                    <Select
                      className={classes.sizeInc}
                      className={`w-100 ${classes.sizeInc}`}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={admin}
                      onChange={(e) => setAdmin(e.target.value)}
                    >
                      <MenuItem value={"Admin"}>Admin</MenuItem>
                      <MenuItem value={"SuperUser"}>SuperUser</MenuItem>
                      <MenuItem value={"User"}>User</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Row>

              <div className="test-TwoBtn">
                <div>
                  {isDisabled ? (
                    <Button type="submit" className="dialog-btn" disabled>
                      Save
                    </Button>
                  ) : (
                    <Button type="submit" className="dialog-btn">
                      Save
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    className="dialog-btn close-btn-abc"
                    onClick={() => handleCloseR()}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Form>
          </DialogContent>
        </Dialog>
        {/* Form Ended */}
      </div>
      <div
        className="w-100"
      // style={{ height: 500, width: '100%', marginTop: '20px', backgroundColor: 'silver' }}
      >
        {/* <DataGrid rows={causeData} columns={columns} pageSize={7} checkboxSelection /> */}
        {userRole == "Admin" ? (
          loading ? (
            <div className="skeletonAdjust">
              <SkeletonTheme color="#A1CDE4" highlightColor="#039BE5">
                <Skeleton count={8} duration={1} />
              </SkeletonTheme>
            </div>
          ) : (
            <MaterialTable
              icons={tableIcons}
              style={{
                marginTop: "10px",
                whiteSpace: "nowrap",
                // maxWidth: "150%",
                tableLayout: "fixed",
              }}
              title="Users"
              columns={columns}
              data={userData}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...userData];
                      const index = oldData.id;

                      // console.log(index)
                      dataUpdate[index] = newData;
                      // console.log(newData);
                      axios
                        .post(
                          "https://defectbe.pakistanautomation.com.pk/api/users/updateUser",
                          newData
                        )
                        .then((res) => {
                          if (res.data.results === "Success") {
                            console.log("updatedSuccessfully");
                            console.log(newData);
                            skuData();
                            toast.success("Update successfully !");
                           }
                        }).catch((error)=>{
                          // error from catch
                          console.log("error from catch ==>", error);
                          if(error.message==="Network Error"){
                            console.log("i am here");
                            toast.error("Server Error!");
                            setIsDisabled(false)
                            return;
                  
                          }
                         
                          else if (error.response.status === 409) {
                            toast.error("User already Exist");
                            setIsDisabled(false);
                            return;
                          } 
                          else {
                            toast.error("Error !");
                            return
                          }
                         
                        })
                      // setCauseData([...dataUpdate]);
                      resolve();
                    }, 1000);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // const dataDelete = [...causeData];
                      const index = oldData.id;
                      axios
                        .post(
                          "https://defectbe.pakistanautomation.com.pk/api/users/deleteUser",
                          {
                            id: index,
                          }
                        )
                        .then((res) => {
                          if (res.data.results === "Success") {
                            console.log("updatedSuccessfully");
                            // console.log(newData);
                            skuData();
                            toast.info("Delete successfully !");
                          // } else {
                          //   console.log(res.data.results);
                          //   // console.log(newData);
                          //   toast.error("Error !");
                          }
                        }).catch((error)=>{
                          // error from catch
                          console.log("error from catch ==>", error);
                          if(error.message==="Network Error"){
                            console.log("i am here");
                            toast.error("Server Error!");
                            setIsDisabled(false)
                            return;
                  
                          }
                          else if(error.response.status === 404){
                            console.log("i am internet error",error.message);
                            toast.error("Please Check your Internet!");
                            setIsDisabled(false)
                            return;
                  
                          }
                          
                          else {
                            toast.error("Error !");
                            return
                          }
                         
                        })
    

                      resolve();
                    }, 1000);
                  }),
              }}
              // actions={[
              //     {

              //         icon: 'save',
              //         tooltip: 'Save User',

              //         icon: () => <EditIcon style={{ color: 'black' }} />,
              //         tooltip: "Modification in process...",
              //         //   onClick: (event, rowData) => handleOpenForm(rowData)
              //     },
              // ]}
              options={{
                filtering: true,
                exportButton: true,
                // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],

                headerStyle: {
                  backgroundColor: "#2E3B55",
                  color: "white",
                },
              }}
            />
          )
        ) : (
          <MaterialTable
            icons={tableIcons}
            actions={[
              {
                disabled: true,
                icon: "edit",
                tooltip: "Edit Team",
                onClick: (event, rowData) => {
                  // Do save operation
                },
              },
              {
                disabled: true,
                icon: "delete",
                tooltip: "Delete Team",
                onClick: (event, rowData) => {
                  // Do save operation
                },
              },
            ]}
            style={{
              marginTop: "10px",
              whiteSpace: "nowrap",
              // maxWidth: "150%",
              tableLayout: "fixed",
            }}
            title="Team"
            columns={columns}
            data={userData}
            //  editable={{
            //    onRowUpdate: (newData, oldData) =>
            //      new Promise((resolve, reject) => {
            //        setTimeout(() => {
            //          const dataUpdate = [...causeData];
            //          const index = oldData.id;

            //          // console.log(index)
            //          dataUpdate[index] = newData;
            //          console.log(newData);
            //          setCauseData([...dataUpdate]);
            //          resolve();
            //        }, 1000);
            //      }),
            //    onRowDelete: (oldData) =>
            //      new Promise((resolve, reject) => {
            //        setTimeout(() => {
            //          const dataDelete = [...causeData];
            //          const index = oldData.id;
            //          // console.log(index)
            //          // console.log(oldData);
            //          dataDelete.splice(index, 1);
            //          setCauseData([...dataDelete]);

            //          resolve();
            //        }, 1000);
            //      }),
            //  }}
            // actions={[
            //     {

            //         icon: 'save',
            //         tooltip: 'Save User',

            //         icon: () => <EditIcon style={{ color: 'black' }} />,
            //         tooltip: "Modification in process...",
            //         //   onClick: (event, rowData) => handleOpenForm(rowData)
            //     },
            // ]}
            options={{
              filtering: true,
              exportButton: true,
              // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],

              headerStyle: {
                backgroundColor: "#2E3B55",
                color: "white",
              },
            }}
          />
        )}
      </div>
    </div>
  );
}
