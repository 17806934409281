import React, { useState, useEffect } from 'react'
import axios from 'axios';
import moment from 'moment'
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import { Card, CardContent, Typography, Grid } from '@material-ui/core'
import CountUp from 'react-countup'
import PropTypes from 'prop-types';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import resetIcon from './icon//reset.png'
import Chart from "react-apexcharts";



const colors = scaleOrdinal(schemeCategory10).range();
//pie chart
//end

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};


const UseStyles = makeStyles((theme) => ({
    roots: {
        "& > *": {
            margin: theme.spacing(1),
            display: "flex",
            // border: '1px solid black',

        },
    },
}));

export default function DefactHandlingDashboard() {

    const classes = UseStyles();
    // const data = [
    //     {
    //         name: 'Page A',
    //         uv: 4000,
    //         pv: 2400,
    //         amt: 2400,
    //     },
    //     {
    //         name: 'Page B',
    //         uv: 3000,
    //         pv: 1398,
    //         amt: 2210,
    //     },
    //     {
    //         name: 'Page C',
    //         uv: 2000,
    //         pv: 9800,
    //         amt: 2290,
    //     },
    //     {
    //         name: 'Page D',
    //         uv: 2780,
    //         pv: 3908,
    //         amt: 2000,
    //     },
    //     {
    //         name: 'Page E',
    //         uv: 1890,
    //         pv: 4800,
    //         amt: 2181,
    //     },
    //     {
    //         name: 'Page F',
    //         uv: 2390,
    //         pv: 3800,
    //         amt: 2500,
    //     },
    //     {
    //         name: 'Page G',
    //         uv: 3490,
    //         pv: 4300,
    //         amt: 2100,
    //     },
    // ];


    const [selectedDate, setSelectedDate] = React.useState(moment());
    const [selectedDate2, setSelectedDate2] = React.useState(moment());
    const [defect, setDefect] = useState([])
    const [openDefacts, setOpenDefacts] = useState([])
    const [createdDefacts, setCreatedDefacts] = useState([])
    const [openDuration, setOpenDuration] = useState([])
    const [countStatus, setCountStatus] = useState([])
    const [departmentDefects, setDepartmentdefects] = useState([])
    const [machineDefects, setMachinedefects] = useState([])
    const [countAnamoly, setCountAnamoly] = useState([])
    const [countDefectPart, setCountDefectPart] = useState([])
    const [countSolvedDefect, setCountSolvedDefect] = useState([])
    const [aoc, setAoc] = useState([{
        DAYS: "", DEFECT: 0
    }])
    const [openDu, setOpenDu] = useState([{
        anamoly: "",
        TimeDiff: ""
    }])
    const [loading, setLoading] = useState(true)

    const [stat, setStat] = useState({
        Open: 0,
        Solved: 0
    })

    // Filter Date1 and Date2
    const handleDateChange = (date) => {
        let changeDateFormat = moment(date).format('YYYY-MM-DD')
        console.log('changeDateFormat1', changeDateFormat)
        setSelectedDate(changeDateFormat);
    };
    const handleDateChange2 = async (date2) => {
        let changeDateFormat2 = moment(date2).format('YYYY-MM-DD')
        console.log('1stchangeDateFormat2', changeDateFormat2)
        setSelectedDate2(changeDateFormat2);
        // submitFilter()
        console.log('2ndchangeDateFormat2', changeDateFormat2)
        // Filter work Start

        console.log("date inside fucntion", selectedDate)
        console.log("date inside fucntion2", selectedDate2)
        //for machine

        const MachD = await axios.post("https://defectbe.pakistanautomation.com.pk/api/defectFilter/CountMachineFilter", {
            startDate: selectedDate,
            endDate: changeDateFormat2
        })
        console.log("filterMachineData", MachD.data)
        setMachinedefects(MachD.data.filter)
        //for area

        const DeparD = await axios.post("https://defectbe.pakistanautomation.com.pk/api/defectFilter/CountAreaFilter", {
            startDate: selectedDate,
            endDate: changeDateFormat2
        })
        setDepartmentdefects(DeparD.data.filter)
        // for defects

        const countAnamol = await axios.post("https://defectbe.pakistanautomation.com.pk/api/defectFilter/CountDefectFilter", {
            startDate: selectedDate,
            endDate: changeDateFormat2
        })
        setCountAnamoly(countAnamol.data.filter)
        console.log(countAnamol.data)
        // for status
        const countstats = await axios.post("https://defectbe.pakistanautomation.com.pk/api/defectFilter/CountStatusFilter", {
            startDate: selectedDate,
            endDate: changeDateFormat2
        })
        setCountStatus(countstats.data.filter)
        console.log(countstats.data)
        // for created by

        const createdD = await axios.post("https://defectbe.pakistanautomation.com.pk/api/defectFilter/CountCreateByFilter", {
            startDate: selectedDate,
            endDate: changeDateFormat2
        })
        setCreatedDefacts(createdD.data.filter)
        // for open Defect
        const OpenD = await axios.post("https://defectbe.pakistanautomation.com.pk/api/defectFilter/CountOpenDefectFilter", {
            startDate: selectedDate,
            endDate: changeDateFormat2
        })
        setOpenDefacts(OpenD.data.filter)
        // for open defect duration
        const OpenDur = await axios.post("https://defectbe.pakistanautomation.com.pk/api/defectFilter/CountOpenDefectDurationFilter", {
            startDate: selectedDate,
            endDate: changeDateFormat2
        })
        console.log(OpenDur)
        setOpenDuration(OpenDur.data.filter)

        const countDefectPart = await axios.post("https://defectbe.pakistanautomation.com.pk/api/defectFilter/CountRepeatedDefectPartFilter", {
            startDate: selectedDate,
            endDate: changeDateFormat2
        })
        setCountDefectPart(countDefectPart.data.filter)
        console.log(countDefectPart.data)

        const countSolvedDefect = await axios.post("https://defectbe.pakistanautomation.com.pk/api/defectFilter/CountSolvedDefectFilter", {
            startDate: selectedDate,
            endDate: changeDateFormat2
        })
        setCountSolvedDefect(countSolvedDefect.data.filter)
        // Filter work End
    };


    // All Data
    const defectData = async () => {
        const fetchedData = await axios.get("https://defectbe.pakistanautomation.com.pk/api/defects/selectDefect")
        setDefect(fetchedData.data.data)
        DCal(fetchedData.data.data)
        setLoading(false)
        console.log(stat)

    }
    const OpenDefect = async () => {
        const OpenD = await axios.get("https://defectbe.pakistanautomation.com.pk/api/defects/openDefect")
        setOpenDefacts(OpenD.data)


    }
    const DepartmentDefects = async () => {
        const DeparD = await axios.get("https://defectbe.pakistanautomation.com.pk/api/defects/CountDepartment")
        setDepartmentdefects(DeparD.data)
        console.log('department data ====>', DeparD)


    }
    const MachineDefects = async () => {
        const MachD = await axios.get("https://defectbe.pakistanautomation.com.pk/api/defects/CountMachine")
        setMachinedefects(MachD.data)


    }

    const CreatedDefect = async () => {
        const createdD = await axios.get("https://defectbe.pakistanautomation.com.pk/api/defects/createdDefect")
        setCreatedDefacts(createdD.data)
    }

    const OpenDuration = async () => {
        const OpenDur = await axios.get("https://defectbe.pakistanautomation.com.pk/api/defects/OpenDuration")
        console.log(OpenDur)
        setOpenDuration(OpenDur.data)
    }

    const CountStat = async () => {
        const countstats = await axios.get("https://defectbe.pakistanautomation.com.pk/api/defects/CountStatus")
        setCountStatus(countstats.data)
        console.log(countstats.data)

    }
    const CountAnamoly = async () => {
        const countAnamol = await axios.get("https://defectbe.pakistanautomation.com.pk/api/defects/CountAnamoly")
        setCountAnamoly(countAnamol.data)
        console.log(countAnamol.data)
    }

    const CountDefectPart = async () => {
        const countDefectPart = await axios.get("https://defectbe.pakistanautomation.com.pk/api/defects/CountDefectPart")
        setCountDefectPart(countDefectPart.data)
        console.log(countDefectPart.data)
    }
    const CountSolvedDefect = async () => {
        const countSolvedDefect = await axios.get("https://defectbe.pakistanautomation.com.pk/api/defects/CountSolvedDefect")
        setCountSolvedDefect(countSolvedDefect.data)
        console.log(countSolvedDefect.data)
    }



    function DCal(dat) {
        console.log(dat)
        let Open = dat.filter(item => item.defectStatus == 'Open');
        console.log(Open);
        let Solved = dat.filter(item => item.defectStatus == 'Solved');
        console.log(Solved);
        setStat({
            Open: Open.length,
            Solved: Solved.length
        })

    }





    function DataSorted() {
        //Dept Sortion
        let dept = departmentDefects.sort(function (a, b) {
            let deptA = a.Total
            let deptB = b.Total
            if (deptA < deptB) {
                return -1;
            }
            else if (deptA > deptB) {
                return 1;
            }
            return 0;
        });


        console.log(dept)

        //machine Sortion
        let mac = machineDefects.sort(function (a, b) {
            let macA = a.Total
            let macB = b.Total
            if (macA < macB) {
                return -1;
            }
            else if (macA > macB) {
                return 1;
            }
            return 0;
        });
        console.log(mac)
        //Age of Call Data Sortion
        let od = openDuration.sort(function (a, b) {
            let aocA = a.DAYS
            let aocB = b.DAYS
            if (aocA < aocB) {
                return -1;
            }
            else if (aocA > aocB) {
                return 1;
            }
            return 0;
        });
        console.log(od)
        //Open Defect Sortion

        let openDef = openDefacts.sort(function (a, b) {
            let openA = a.Total
            let openB = b.Total
            if (openA < openB) {
                return -1;
            }
            else if (openA > openB) {
                return 1;
            }
            return 0;
        });
        console.log(openDef)

        //Total Anamoly Data Sortion
        let anamoly = countAnamoly.sort(function (a, b) {
            let dateA = a.Total
            let dateB = b.Total
            if (dateA < dateB) {
                return -1;
            }
            else if (dateA > dateB) {
                return 1;
            }
            return 0;
        });


        console.log(anamoly)


    }
    DataSorted()
    var arraoc = []
    function ageOfCall() {
        let newOD = openDuration;
        let fifteenDays = newOD.filter(item => item.DAYS >= 0 && item.DAYS <= 15)
        let thirtyDays = newOD.filter(item => item.DAYS >= 16 && item.DAYS <= 30)
        let nintydays = newOD.filter(item => item.DAYS > 30)
        console.log(newOD)
        console.log(fifteenDays)
        console.log(thirtyDays)
        console.log(nintydays)

        let one = { DAYS: "0-15", DEFECT: fifteenDays.length }
        let two = { DAYS: "15-30", DEFECT: thirtyDays.length }
        let three = { DAYS: '>30', DEFECT: nintydays.length }
        return [one, two, three]

    }
    const [one, two, three] = ageOfCall()
    console.log(one, two, three)
    arraoc.push(one, two, three)
    console.log("age of calll log",arraoc)

    const handleReset = () => {
        ageOfCall()
        defectData()
        OpenDefect()
        CreatedDefect()
        OpenDuration()
        CountStat()
        DepartmentDefects()
        MachineDefects()
        CountAnamoly()
        setSelectedDate(moment())
        setSelectedDate2(moment())
        CountDefectPart()
        CountSolvedDefect()
    }

    useEffect(() => {
        ageOfCall()
        defectData()
        OpenDefect()
        CreatedDefect()
        OpenDuration()
        CountStat()
        DepartmentDefects()
        MachineDefects()
        CountAnamoly()
        CountDefectPart()
        CountSolvedDefect()
        console.log(defect)
    }, [])

                                        //graph charts work start


    //dashboard defects graph start
    // console.log("department data in state====>", departmentDefects)
    const dashboardGraphState = {
        options: {
            chart: {
                id: "basic-bar"
            },
            plotOptions: {
                bar: {
                    barHeight: "100%",
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: "bottom",
                    },
                },

            },
            colors: [
                "#33b2df",
                "#546E7A",
                "#d4526e",
                "#13d8aa",
                "#A5978B",
                "#2b908f",
                "#f9a3a4",
                "#90ee7e",
                "#f48024",
                "#69d2e7",
            ],
            dataLabels: {
                style: {
                    colors: ["black"],
                },
            },
            stroke: {
                width: 1,
                colors: ["#fff"],
            },
            xaxis: {
                // categories: ['Pakistan', 'India', 'Germany', 'USA', 'UK', 'South Africa', 'Mexico', 'Sri Lanka']
                // categories: departmentDefects?.map((dept, index)=> console.log("department data map,", dept.department ))
                categories: departmentDefects?.map((dept) => (dept.department))
            },
            title: {
                text: 'Department Defects ',
                align: 'center',
                floating: true
            },
        },
        series: [
            {
                name: "Total",
                // data: [30, 40, 45, 50, 49, 60, 70, 91],
                // data: departmentDefects?.map((dept)=> console.log("total from dept ==>", dept.Total))
                data: departmentDefects?.map((dept) => dept.Total)
            }
        ],

    };
    //dashboard defects graph end


    //machine defects graph start
    // console.log("machine data in state ===>", machineDefects);
    const machineGraphState = {
        options: {
            chart: {
                id: "basic-bar"
            },
            plotOptions: {
                bar: {
                    barHeight: "100%",
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: "bottom",
                    },
                },

            },
            colors: [
               "#f08080",
               "#aa393f",
               "#a29088",
               "#895a89",
               "#000080",
               "#e8cab3",
               "#cce7f1",
               "#7d6357",
               "#ae4a3b",
               "#3d3d5b",
            ],
            dataLabels: {
                style: {
                    colors: ["black"],
                },
            },
            stroke: {
                width: 1,
                colors: ["#fff"],
            },
            xaxis: {
                categories: machineDefects?.map((machin) => (machin.machine))
            },
            title: {
                text: 'Machine Defects ',
                align: 'center',
                floating: true
            },
        },
        series: [
            {
                name: "Total",
                // data: [30, 40, 45, 50, 49, 60, 70, 91],
                // data: departmentDefects?.map((dept)=> console.log("total from dept ==>", dept.Total))
                data: machineDefects?.map((machin) => machin.Total)
            }
        ],

    };
    //machin defects graph end


    //Repated Defect Parts graph start
    // console.log("repeated defect from graph ==>", countDefectPart)
    const repatedDefectGraphState = {
        options: {
            chart: {
                id: "basic-bar"
            },
            plotOptions: {
                bar: {
                    barHeight: "100%",
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: "bottom",
                    },
                },

            },
            colors: [
                "#808080",
                "#008000",
                "#1d617a",
                "#968076",
                "#968076",
                "#e9e5e3",
                "#606eb2",
                "#4e4040",
                "#9a9c6c",
                "#608b83",
            ],
            dataLabels: {
                style: {
                    colors: ["black"],
                },
            },
            stroke: {
                width: 1,
                colors: ["#fff"],
            },
            xaxis: {
                categories: countDefectPart?.map((repDefect) => (repDefect.defectPart))
            },
            title: {
                text: 'Repated Defect Parts ',
                align: 'center',
                floating: true
            },
        },
        series: [
            {
                name: "Total",
                // data: [30, 40, 45, 50, 49, 60, 70, 91],
                // data: departmentDefects?.map((dept)=> console.log("total from dept ==>", dept.Total))
                data: countDefectPart?.map((repDefect) => repDefect.Total)
            }
        ],

    };
    //Repated Defect Parts graph end


    //Age of Call graph start
    console.log("arraoc from graph ==>", arraoc);
    const ageOfCallGraphState = {
        options: {
            chart: {
                id: "basic-bar"
            },
            plotOptions: {
                bar: {
                    barHeight: "100%",
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: "bottom",
                    },
                },

            },
            colors: [
                "#cabdb8",
                "#003399",
                "#986d61",
                "#ffc0cb",
                "#bcacac",
                "#cfcfcf",
                "#dbefe1",
                "#c7bdb8",
                "#7d5949",
                "#c08f73",
            ],
            dataLabels: {
                style: {
                    colors: ["black"],
                },
            },
            stroke: {
                width: 1,
                colors: ["#fff"],
            },
            xaxis: {
                categories: arraoc?.map((aOc) => (aOc.DAYS))
            },
            title: {
                text: 'Age Of Call ',
                align: 'center',
                floating: true
            },
        },
        series: [
            {
                name: "Total",
                // data: [30, 40, 45, 50, 49, 60, 70, 91],
                // data: departmentDefects?.map((dept)=> console.log("total from dept ==>", dept.Total))
                data: arraoc?.map((aOc) => aOc.DEFECT)
            }
        ],

    };
    //Age of Call graph end

    //Total Anamolies Reported graph start
    // console.log("total countAnamoly from graph ==> ", countAnamoly);
    const totalAnamoliesDefectGraphState = {
        options: {
            chart: {
                id: "basic-bar"
            },
            plotOptions: {
                bar: {
                    barHeight: "100%",
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: "bottom",
                    },
                },

            },
            colors: [
                "#f4b786",
                "#7db4a2",
                "#b00b69",
                "#19209f",
                "#ffe4e1",
                "#ff8e00",
                "#659db4",
                "#9eadb6",
                "#9a9c6c",
                "#895a89",
            ],
            dataLabels: {
                style: {
                    colors: ["black"],
                },
            },
            stroke: {
                width: 1,
                colors: ["#fff"],
            },
            xaxis: {
                categories: countAnamoly?.map((cAnamoly) => (cAnamoly.anamoly))
            },
            title: {
                text: 'Total Anamolies Reported ',
                align: 'center',
                floating: true
            },
        },
        series: [
            {
                name: "Total",
                // data: [30, 40, 45, 50, 49, 60, 70, 91],
                // data: departmentDefects?.map((dept)=> console.log("total from dept ==>", dept.Total))
                data: countAnamoly?.map((cAnamoly) => cAnamoly.Total)
            }
        ],

    };
    //Total Anamolies Reported graph end


                                            //graph charts work end




    //pie states Bar charts
    console.log("createdDefacts from graph ==>" , createdDefacts)
    const pieChartState = {
        // series: [44, 55, 13, 43, 22],
        series: createdDefacts?.map((cDefect) => cDefect.Total),
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            // labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            labels: createdDefacts?.map((cDefect) => cDefect.wfound),
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 250
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            title: {
                text: 'Defects Reported By ',
                align: 'right',
                floating: true
            },
        },
    }



    return (
        // <div style={{width:"98%"}}>
        <div className='container-fluid mt-2'>
            {/* <div className="container border border-dark rounded my-1" style={{ height: '10vh' }}> */}
            <div className="container p-0 m-0 ">
                <div className="row w-100 d-flex justify-content-between p-0 m-0">
                    <div className='col-md-6 d-flex justify-content-center align-items-center'>
                        <div className='d-flex justify-content-center align-items-center Heading'>
                            <h3 className='fw-bold fst-italic fs-2 Text-Gradient-Color'>Defect Handling Dashboard</h3>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='col-md-10'>
                                <div>
                                    <form className={`${classes.roots}`} noValidate>
                                        {/* <form className={`border border-dark rounded ${classes.roots}`} noValidate> */}
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="datee-picker-dialog"
                                                label="Start Date"
                                                format="yyyy/MM/dd"
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </form>
                                </div>
                                <div>
                                    <form className={classes.roots} noValidate>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog2"
                                                label="End Date"
                                                format="yyyy-MM-dd"
                                                value={selectedDate2}
                                                onChange={handleDateChange2}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </form>
                                </div>
                            </div>
                            <div className='col-md-2 icon-size'>
                                <img
                                    className='w-100 '
                                    role="button"
                                    src={resetIcon}
                                    alt='reset icon'
                                    onClick={handleReset}
                                />

                            </div>
                        </div>
                        {/* <div className='d-flex justify-content-center align-items-center'>
                            <Button
                                style={{
                                    background: 'linear-gradient(45deg, #FE6B8B 30%, red 90%)',
                                    border: "2px solid black",
                                    padding: "8px 8px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "black",
                                    // width: '100%'
                                }}
                                variant="contained"
                                color="secondary"
                                onClick={handleReset}
                                className={classes.submit}
                                endIcon={<SendIcon />}
                            >
                                Reset
                    </Button>
                        </div>
                     */}
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row justify-content-between Card-container">
                    {/* <div className="DHGCards"> */}
                    <div className='col-md-3'>
                        <Card style={{
                            color: "black",
                            backgroundImage: 'linear-gradient(to right, #56e8ab , #1ab0ab)',
                            backgroundColor: '#85FFBD',
                        }}>
                            <CardContent>
                                <Typography gutterBottom variant="body1" component="h4" align="center">
                                    <span className="textSize">Total Defects</span>
                                </Typography>
                                <Typography variant="body1" align="center">

                                    <CountUp start={0} end={defect.length} duration={3} separator={','}></CountUp>
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div className='col-md-3'>
                        <Card style={{
                            color: "black",
                            backgroundImage: 'linear-gradient(to right, #56e8ab , #1ab0ab)',
                            backgroundColor: '#85FFBD',
                        }}>
                            <CardContent>
                                <Typography gutterBottom variant="body1" component="h4" align="center">
                                    <span className="textSize">Open Defects</span>
                                </Typography>
                                <Typography variant="body1" align="center"><CountUp start={0} end={stat.Open} duration={3} separator={','}></CountUp>
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div className='col-md-3'>
                        <Card style={{
                            color: "black",
                            backgroundImage: 'linear-gradient(to right, #56e8ab , #1ab0ab)',
                            backgroundColor: '#85FFBD',
                        }}>
                            <CardContent>
                                <Typography gutterBottom variant="body1" component="h4" align="center">
                                    <span className="textSize">Solved Defects</span>
                                </Typography>
                                <Typography variant="body1" align="center"><CountUp start={0} end={stat.Solved} duration={3} separator={','}></CountUp>
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    {/* </div> */}
                </div>
            </div>

            <div className="container Bar-Chart" >
                <div className="row w-100">
                    <div className="col">
                        {/* {/* <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Department Defects</h3> */} 

                        <Chart

                            options={dashboardGraphState.options}
                            series={dashboardGraphState.series}
                            type="bar"
                            width="300"
                        />
                    </div>
                    <div className="col">

                        {/* {/* <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Machine Defects</h3> */}

                        <Chart

                            options={machineGraphState.options}
                            series={machineGraphState.series}
                            type="bar"
                            width="300"
                        />
                    </div>
                    
                    <div className="col">
                        {/* {/* <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Defects Reported By</h3> */}
                        <Chart
                            className='Pie-chart'
                            options={pieChartState.options} series={pieChartState.series} type="pie" width={380}
                        />
                    </div>
                    <div className="col">
                        {/* {/* <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Repated Defect Parts</h3> */}

                        <Chart

                            options={repatedDefectGraphState.options}
                            series={repatedDefectGraphState.series}
                            type="bar"
                            width="300"
                        />
                    </div>
                    {/* <div className="col">
                        <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Solved Defects</h3>

                        <Chart
                            options={solvedDefectGraphState.options}
                            series={solvedDefectGraphState.series}
                            type="bar"
                            width="300"
                        />
                    </div> */}
                    <div className="col">
                        <Bar dataKey="anamoly" barSize={20} fill="#413ea0" />
                        {/* {/* <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Age Of Call</h3> */} 
                        {console.log(arraoc)}

                        <Chart

                            options={ageOfCallGraphState.options}
                            series={ageOfCallGraphState.series}
                            type="bar"
                            width="300"
                        />
                    </div>
                    {/* <div className="col">

                         <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Open Defects</h3>

                        <Chart

                            options={barChartState.options}
                            series={barChartState.series}
                            type="bar"
                            width="300"
                        />

                    </div> */}

                    <div className="col">

                        {/* {/* <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Total Anamolies Reported</h3> */} 
                        {/* <BarChart
                            // width={1350}
                            width={850}
                            height={190}
                            data={countAnamoly}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="anamoly" tick={{ fontSize: 10 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend /> 
                            <Bar dataKey="Total" fill="#2E3B55" />
                        </BarChart> */}
                        <Chart

                            options={totalAnamoliesDefectGraphState.options}
                            series={totalAnamoliesDefectGraphState.series}
                            type="bar"
                            width="300"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

