import React from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

// export const ForSuccess = () => {
//     const notify = () => toast.success("Inserted successfully !");

//     return (
//         <div>
//             <button onClick={notify}>Notify !</button>
//             <ToastContainer
//                 position="top-right"
//                 autoClose={5000}
//                 hideProgressBar={false}
//                 newestOnTop={false}
//                 closeOnClick
//                 rtl={false}
//                 pauseOnFocusLoss
//                 draggable
//                 pauseOnHover
//             />
//         </div>
//     );
// }
// export const ForError = () => {
//     const notify = () => toast.error("Error !");

//     return (
//         <div>
//             <button onClick={notify}>Notify !</button>
//             <ToastContainer
//                 position="top-right"
//                 autoClose={5000}
//                 hideProgressBar={false}
//                 newestOnTop={false}
//                 closeOnClick
//                 rtl={false}
//                 pauseOnFocusLoss
//                 draggable
//                 pauseOnHover
//             />
//         </div>
//     );
// }
// export const ForUpdate = () => {
//     const notify = () => toast.success("Update successfully !");

//     return (
//         <div>
//             <button onClick={notify}>Notify !</button>
//             <ToastContainer
//                 position="top-right"
//                 autoClose={5000}
//                 hideProgressBar={false}
//                 newestOnTop={false}
//                 closeOnClick
//                 rtl={false}
//                 pauseOnFocusLoss
//                 draggable
//                 pauseOnHover
//             />
//         </div>
//     );
// }

export const ForDelete = () => {
     toast.info("Delete successfully !");

    return (
        <div>
            {/* <button onClick={notify}>Notify !</button> */}
            <ToastContainer 
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
        </div>
    );
}