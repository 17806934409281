
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
// import SignIn from './component/Signin'
import Dashboard from './component/Dashboard'
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { TSetter } from './component/TSetter';

function App() {
  useEffect(()=>{
    console.log('Initially ' + (window.navigator.onLine ? 'on' : 'off') + 'line');

window.addEventListener('online', () => toast.success("You Are Online Now"));
window.addEventListener('offline', () => toast.error("Please Check Your Internet"));  
  })
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={TSetter}></Route>
          <Route path="/Dashboard" component={Dashboard}></Route>
        </Switch>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
