import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt from "jsonwebtoken";
import moment from "moment";
import SelectSearch, { fuzzySearch } from "react-select-search";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";

// Dialog full screen form imports
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

//Material Date Imports
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useForm } from "react-hook-form";

// icons
import ImageIcon from "@material-ui/icons/Image";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";

// test icons
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

//for Toast
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
// import {ForDelete} from './Toast'

// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

//import for form validation mehak edit
// import { TextValidator} from 'react-material-ui-form-validator';

//For Modal
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

//loading
import CircularProgress from "@material-ui/core/CircularProgress";

//bootstrap
import { Form, Row, Col, InputGroup } from "react-bootstrap";

// import { makeStyles } from '@material-ui/core/styles';

//for modal

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: "linear-gradient(45deg, #21CBF3 60%, #21CBF3 90%)",
    boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
    // minHeight: '56px'
  },
  toolbar: {
    minHeight: "56px",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  modal: {
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sizeInc: {
    width: "120%",
  },
  buttonSubmit: {
    //    marginTop:'150px'
  },
  paper: {
    height: "auto",
    // width: '90%',
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(to bottom, #abbaab, #ffffff)",
    border: "2px solid #000",
    borderRadius: "1rem",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "absolute",
    top: "50px",
    left: "30%",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "3",
    float: "right",
  },
  root: {
    "& .super-app-theme--header": {
      backgroundColor: "black",
      color: "white",
    },
  },
  rootSnack: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  rootCirular: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
// Form dialog Transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DefactsHandling() {
  //form validation
  const [validated, setValidated] = useState(false);

  const columns = [
    // {
    //     field: 'id', title: 'ID', cellStyle: {
    //         backgroundColor: '#039be5',
    //         color: '#FFF'
    //     },
    //     headerStyle: {
    //         backgroundColor: '#039be5',
    //     }
    // },
    { field: "department", title: "Department", editable: "never" },
    // { field: 'line', title: 'Line' },
    { field: "hfound", title: "System", editable: "never" },
    { field: "wfound", title: "Who Found?", editable: "never" },
    { field: "priority", title: "Priority", editable: "never" },
    { field: "machine", title: "Machine", editable: "never" },
    { field: "equipmentName", title: "Equipment Owner", editable: "never" },
    { field: "anamoly", title: "Defect", editable: "never" },


    {
      field: "defectStatus",
      title: "Defect Status",
      lookup: { Open: "Open", Solved: "Solved" },
    },
    { field: "responsible", title: "Responsible", editable: "never" },
    { field: "defectPart", title: "Defect Part", editable: "never" },
    { field: "repeatedDefect", title: "Repeated Defect", editable: "never" },
    { field: "targetDate", title: "Founded On", editable: "never" },
    { field: "closeDate", title: "Expected Close", editable: "never" },
    { field: "createdOn", title: "Created On", editable: "never" },
    { field: "createdBy", title: "Created By", editable: "never" },
    { field: "solvedDate", title: "Defect Solved", editable: "never" },
    { field: "actionPlan", title: "Action Taken/Plan", editable: "never" },
    { field: "anamolyDetail", title: "Detail", editable: "never" },

    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 90,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    // },
  ];
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openR, setOpenR] = React.useState(false);
  const handleOpenR = () => {
    setOpenR(true);
    reset();
  };

  const handleCloseR = () => {
    setOpenR(false);
  };

  const classes = useStyles();

  const [SelectAnamoly, setAnamoly] = useState("");
  const [DefactStat, setDefactStat] = useState("Open");
  const [AmDetail, setAmDetail] = useState("");
  const [Respon, setRespon] = useState("");
  const [ActionPlan, setActionPlan] = useState("");
  const [TD, setTD] = useState(new Date());
  const [CD, setCD] = useState(new Date());
  const [tableDATA, setTableDATA] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Departmentdata, setDepartmentData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [Machinedata, setMachineData] = useState([]);
  const [systemNameData, setSystemNameData] = useState([]);
  const [defectTypeData, setDefectTypeData] = useState([]);
  const [equipmentName, setEquipmentName] = useState("");
  console.log("equipmentName ===>", equipmentName.toString());

  // const [Linedata, setLineData] = useState([])
  const [selectedMachine, setSelectedMachine] = useState("");
  // const [line, setLine] = useState("")
  const [hfound, setHfound] = useState("");
  const [wfound, setWfound] = useState(localStorage.getItem("username"));
  const [priority, setPriority] = useState("");
  const [repeatedDefect, setRepeatedDefect] = useState("");
  const [defectPart, setDefectPart] = useState("");
  const [userRole, setUserRole] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [email, setEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState([]);
  const [feedbackDataIndividual, setFeedbackDataIndividual] = useState([]);
  const [defectPartData, setDefectPartData] = useState([]);

  //For Toast
  const [isDisabled, setIsDisabled] = useState(false);

  //For Modal
  const [openModal, setOpenModal] = useState(false);
  const [imageforModal, setImageforModal] = useState("");
  const [isImage, setIsImage] = useState(true);

  const handleOpenModal = (data) => {
    if (!isImage) {
      return;
    }
    console.log("testing from modal ....", data);
    console.log("testing from modal image....", data.imageFile);
    setImageforModal(data.imageFile);
    // console.log("tableDATA from openModal", tableDATA);
    setOpenModal(true);
    setIsImage(false);
    console.log("setIsImage is fasle");
  };
  const handleCloseModal = () => {
    setImageforModal("");
    setOpenModal(false);
    setIsImage(true);
    console.log("setIsImage is again true");
  };

  //Month Work
  let MonthArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let getCurrentMonth = MonthArr[new Date().getMonth()];
  console.log(getCurrentMonth);

  const handleClickOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };
  const handleChange = (event) => {
    setAnamoly(event);
  };
  const handleChangeStatus = (event) => {
    setDefactStat(event);
  };

  //Fetching Data for Table
  const defectData = async () => {
    const fetchedData = await axios.get(
      "https://defectbe.pakistanautomation.com.pk/api/defects/selectDefect"
    );
    console.log("defectData", fetchedData.data);
    // fetchedData.data.data.map(item => {
    //     if (item.repeatedDefect === 0) {
    //         item.repeatedDefect = 'No'
    //     }
    //     else {
    //         item.repeatedDefect = 'Yes'
    //     }
    // })
    setTableDATA(fetchedData.data);
    setLoading(false);
  };
  //  Fetching Data For Departements
  const getdepartments = async () => {
    const fetchedData = await axios.get(
      "https://defectbe.pakistanautomation.com.pk/api/departments/getDepartment"
    );
    console.log(fetchedData.data);
    setDepartmentData(fetchedData.data.department);
  };
  //Fetching Data For Machines
  // const getmachines = async () => {
  //   const fetchedData = await axios.get(
  //     "https://defectbe.pakistanautomation.com.pk/api/machines/getTypes"
  //   );
  //   console.log("getMachines ====>", fetchedData.data);
  //   setMachineData(fetchedData.data.mtype);
  // };
  //Fetching Data For SystemNames
  const SystemNames = async () => {
    const fetchedData = await axios.get(
      "https://defectbe.pakistanautomation.com.pk/api/systems/getSystems"
    );
    console.log(fetchedData.data);
    setSystemNameData(fetchedData.data.systemName);
  };
  //Fetching Data For Defect Types
  const DefectTypes = async () => {
    const fetchedData = await axios.get(
      "https://defectbe.pakistanautomation.com.pk/api/defectTypes/getDefectTypes"
    );
    console.log(fetchedData.data);
    setDefectTypeData(fetchedData.data.defectType);
  };
  //Fetching Data For Defect Parts
  // const DefectParts = async () => {
  //   const fetchedData = await axios.get(
  //     "https://defectbe.pakistanautomation.com.pk/api/defectParts/getDefectParts"
  //   );
  //   console.log(fetchedData.data);
  //   setDefectPartData(fetchedData.data.defectPart);
  // };
  //Fetching Data For Users
  const UserNames = async () => {
    const fetchedData = await axios.get(
      "https://defectbe.pakistanautomation.com.pk/api/users/getUsers"
    );
    console.log("user Data", fetchedData.data);
    setUsers(fetchedData.data);
  };
  // Fething user Indivi  dual Feedback
  const getIndividualFeedBack = async () => {
    console.log("USERid", userId);
    const fetchData = await axios.post(
      `https://defectbe.pakistanautomation.com.pk/api/defects/SelectIndiFeedback/${userId}`
    );
    console.log("individualData", fetchData.data.data);
    setFeedbackDataIndividual(fetchData.data);
  };

  useEffect(() => {
    defectData();
    getdepartments();
    // getmachines();
    SystemNames();
    // DefectParts();
    UserNames();
    DefectTypes();
    getIndividualFeedBack();
    // getLines()
    var token = localStorage.getItem("token");
    console.log("token:" + token);
    var decoded = jwt.decode(token, { complete: true });
    console.log(decoded);
    const isAdmin = decoded.payload.defectRole;
    console.log(isAdmin);
    setUserRole(isAdmin);
    const userEmail = decoded.payload.email;
    console.log(userEmail);
    setEmail(userEmail);
    const userId = decoded.payload.id;
    console.log(userId);
    setUserId(userId);
  }, [userId]);

  //for clearing form mehak edit
  const clearForm = () => {
    setSelectedDepartment("");
    // setLine("");
    setSelectedMachine("");
    setHfound("");
    // setWfound("");
    setPriority("");
    setAnamoly("");
    // setDefactStat("");
    setRespon("");
    setActionPlan("");
    setAmDetail("");
    setTD(new Date());
    setCD(new Date());
    setRepeatedDefect("");
    setDefectPart("");
    setEquipmentName("");
  };

  //Form Submit Handler
  const submitchecker = () => {
    console.log("submitchecker");
  };
  // For Select Search Changing Fetched Data to required format data
  var departmentOption = Departmentdata.map((item) => ({
    name: item.department,
    value: item.department,
  }));
  var machineOption = Machinedata.map((item) => ({
    name: item.machineTypes,
    value: item.machineTypes,
  }));
  var systemOption = systemNameData.map((item) => ({
    name: item.systemName,
    value: item.systemName,
  }));
  var defectTypeOption = defectTypeData.map((item) => ({
    name: item.defectType,
    value: item.defectType,
  }));
  var defectPartOption = defectPartData.map((item) => ({
    name: item.defectPart,
    value: item.defectPart,
  }));
  var founderOption = users.map((item) => ({
    name: item.username,
    value: item.username,
  }));
  var responsibleOption = users.map((item) => ({
    name: item.username,
    value: item.username,
  }));

  const [photos, setPhotos] = useState("");

  //selectedMachinHandler for get Equipment Owner Name
  const selectedDepartHandler = (value) => {
    console.log("value from setSelectedDepartment ==>", value);
    setSelectedDepartment(value);
    console.log("value from setSelectedDepartment and dept ==>", selectedDepartment);

    axios
      .post("https://defectbe.pakistanautomation.com.pk/api/machines/getMachinesType", {
        department: value,
      })
      .then((res) => {
        console.log("response from selectedDepartHandler ==>", res);
        console.log("response from selectedDepartHandler ==>", res.data.data);
        setMachineData(res.data.data);

        if (res.data.results === "Success") {
          console.log(res.data.message);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error from selectedDepartHandler ==>", error);
      });
  };

  //selectedMachinHandler for get Equipment Owner Name
  const selectedMachineHandler = (value) => {
    console.log("value from selectedMachine ==>", value);
    setSelectedMachine(value);
    console.log("value from selectedMachine and dept ==>", selectedDepartment);

    axios
      .post("https://defectbe.pakistanautomation.com.pk/api/machines/getOwnerName", {
        machineType: value,
        department: selectedDepartment,
      })
      .then((res) => {
        console.log("response from selectedMachineHandler ==>", res);
        console.log("response from selectedMachineHandler ==>", res.data.data);
        setEquipmentName(res.data.data?.map((eName) => eName.equipmentName));

        if (res.data.results === "Success") {
          console.log(res.data.message);


          //request for defect part 
          axios
          .post("https://defectbe.pakistanautomation.com.pk/api/defectParts/getDefectPartsFromMachine", {
            machineType: value,
          })
          .then((res) => {
            console.log("response from getDefectPartsFromMachine ==>", res);
            console.log("response from getDefectPartsFromMachine data  ==>", res.data.defectPart);
            setDefectPartData(res.data.defectPart);
    
            if (res.data.results === "Success") {
              console.log(res.data.message);
            } else {
              console.log(res.data.message);
            }
          })
          .catch((error) => {
            console.log("error from getDefectPartsFromMachine ==>", error);
          });
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error from selectMachinhanler ==>", error);
      });
  };
  console.log("selectedMACHIN ===>", selectedMachine);

  //setHFoundHandler function
  const setHfoundHanlde = (value) => {
    console.log("value of hfound ==>", value);
    setHfound(value);
    if (value === "Quality") {
      setPriority("High");
    } else if (value === "Safety") {
      setPriority("High");
    } else {
      setPriority("");
    }

    console.log("priority ==>", priority, hfound);
  };
  console.log("priority outside ==>", priority, hfound);

  function onSubmit1(e) {
    e.preventDefault();
    setWfound(localStorage.getItem("username"));

    var fileInput = document.getElementById("image");
    console.log("image", fileInput.files[0]);
    // if (fileInput.files[0] == null) {
    //     setIsDisabled(false)
    //     toast.warn("Please add file!");
    //     // alert("Please add file")
    //     return;
    // }
    console.log("selectedDept ==>", selectedDepartment);
    console.log("machineTypeId ==>", selectedMachine);
    console.log("hFoundId ==>", hfound);
    console.log("priorityId ==>", priority);
    console.log("selectedAnomalyId ==>", SelectAnamoly);
    console.log("defectStatId ==>", DefactStat);
    console.log("defectPartId ==>", defectPart);
    console.log("repeatedDefectId ==>", repeatedDefect);
    console.log("wFoundId ==>", wfound);
    // console.log("responId ==>", )
    // if (
    //     selectedDeptId == null &&
    //     machineTypeId == null &&
    //     hFoundId == null &&
    //     priorityId == null &&
    //     selectedAnomalyId == null &&
    //     defectStatId == null &&
    //     defectPartId == null &&
    //     repeatedDefectId == null &&
    //     wFoundId == null &&
    //     responId == null
    // ) {
    //     setIsDisabled(false)
    //     // alert("Please input correct values")
    //     toast.warn("Please fill empty fields !");

    //     return;
    // }
    if (!selectedDepartment) {
      // alert("error")
      toast.warn("Please fill Area fields !");
      return;
    }
    if (!selectedMachine) {
      toast.warn("Please fill Machine fields !");

      // alert("error")
      return;
    }
    if (!equipmentName) {
      toast.warn("Please fill Equipment Owner Name !");
      // alert("error")
      return;
    }
    if (!hfound) {
      toast.warn("Please fill empty fields !");

      // alert("error")
      return;
    }
    if (!wfound) {
      toast.warn("Please fill Founded fields !");

      // alert("error")
      return;
    }
    // if (!priority) {
    //   toast.warn("Please fill priority fields !");

    //   // alert("error")
    //   return;
    // }
    if (!SelectAnamoly) {
      toast.warn("Please fill Anamoly fields !");

      // alert("error")
      return;
    }
    if (!DefactStat) {
      toast.warn("Please fill Defect status fields !");

      // alert("error")
      return;
    }
    if (!Respon) {
      toast.warn("Please fill Responsible fields !");
      // alert("error")
      return;
    }
    if (!repeatedDefect) {
      // alert("error")
      toast.warn("Please fill Defect repeated fields !");

      return;
    }
    if (!defectPart) {
      toast.warn("Please fill Defect part fields !");
      // alert("error")
      return;
    }
    // if (!userId) {
    //     toast.warn("Please fill empty fields !");
    //     // alert("error")
    //     return
    // }
    // if (!selectedDepartment &&
    //      !selectedMachine &&
    //      !hfound &&
    //      !wfound &&
    //      !priority &&
    //      !SelectAnamoly &&
    //      !DefactStat &&
    //      !Respon &&
    //      !repeatedDefect &&
    //      !defectPart &&
    //      !userId
    // ) {
    //     alert("error")
    //     return
    // }

    setValidated(true);
    setIsDisabled(true);
    let formData = new FormData();

    formData.append("imageFile", fileInput.files[0]);
    formData.append("department", selectedDepartment);
    formData.append("machine", selectedMachine);
    formData.append("equipmentName", equipmentName);
    formData.append("hfound", hfound);
    formData.append("wfound", wfound);
    formData.append("priority", priority);
    formData.append("anamoly", SelectAnamoly);
    formData.append("defectStatus", DefactStat);
    formData.append("anamolyDetail", AmDetail);
    formData.append("responsible", Respon);
    formData.append("actionPlan", ActionPlan);
    formData.append("targetDate", moment(TD).format("YYYY-MM-DD hh:mm:ss"));
    formData.append("closeDate", moment(CD).format("YYYY-MM-DD hh:mm:ss"));
    formData.append("createdOn", moment().format("YYYY-MM-DD hh:mm:ss"));
    formData.append("createdBy", localStorage.getItem("username"));
    formData.append("repeatedDefect", repeatedDefect);
    formData.append("defectMonth", getCurrentMonth);
    formData.append("defectPart", defectPart);
    formData.append("userId", userId);
    formData.append("defectYear", moment().format("YYYY"));
    // console.log(formData)

    axios({
      method: "post",
      url: "https://defectbe.pakistanautomation.com.pk/api/defects/createDefect",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(
      (res) => {
        if (res.status === 200) {
          console.log("response from image =====>", res);
          setPriority("");
          toast.success("Inserted successfully !");
          defectData()

          setIsDisabled(false);


          defectData();
          clearForm();
          handleCloseForm();
          // console.log(tableDATA)

          // console.log(res.data.message);
          // console.log("ressssssssssdattttttttt", res.data);
          console.log("ddddddddddddda" + res.status);
          document.getElementById("image").value = "";

        } else if (res.status === 401) {
          // if data not posted succesfullys
          // console.log(res.data.message);
          setIsDisabled(false);
          toast.error("Please fill all the fields!");
        }
        // else if(res.message==="Network Error"){
        //   console.log("resssssssssssssssss",res);
        //   toast.error("Network Error")
        //   setIsDisabled(false)

        //   return
        // }



        else {
          // if data not posted succesfullys
          // console.log(res.data.message);
          setIsDisabled(false);
          toast.error("Error !");
          return

        }
      },
      (error) => {
        setPriority("");
        // console.log(error.response.data);
        setIsDisabled(false);
        console.log(error.message)
        //errors getting
        console.log("error from catch ==>", error);
          if(error.message==="Network Error"){
            console.log("i am here");
            toast.error("Server Error!");
            setIsDisabled(false)
            return;
  
          }
         
          else if (error.response.status === 409) {
            toast.error("System Already Exist");
            setIsDisabled(false);
            return;
          } 
          else {
            toast.error("Error !");
            return
          }
         
        
        // if(error.message === 'Network Error'){
        //   console.log('i m here')
        //   toast.error(error.message)
        //   return
        // }
        // console.log("errorFromFormData", error);
        // toast.error(error.response.data.message);
      }
    )
  }

  // console.log("photos ====>", photos);

  return (
    <div className="w-90  m-0 p-0 DHContainer">
      <div>
        {/* Full form Dialog */}
        <Button
          variant="outlined"
          className="mt-4"
          color="secondary"
          onClick={handleClickOpenForm}
        >
          Report Defect
        </Button>
        <Dialog
          fullScreen
          open={openForm}
          onClose={handleCloseForm}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }} className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseForm}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                REPORT DEFECT
              </Typography>
            </Toolbar>
          </AppBar>
          <Form
            noValidate
            // style={{ background: 'linear-gradient(45deg, #faffd1 20%, #a1ffce 90%)' }}
            // style={{ background: "linear-gradient(45deg, #faffd1, #22B2F4)", height: '100vh' }}
            style={{ background: "linear-gradient(45deg, #faffd1, #22B2F4)" }}
            onSubmit={handleSubmit(submitchecker)}
            className="dhForm d-flex justify-content-center align-items-center dh-form-for-mobile-resp"
          >
            <div>
              <SelectSearch
                {...register("selectedDepartment", { required: true })}
                id="selectedDeptId"
                value={selectedDepartment}
                // onChange={(e) => setSelectedDepartment(e)}
                onChange={(e) => selectedDepartHandler(e)}
                options={departmentOption}
                search
                filterOptions={fuzzySearch}
                placeholder="Select your Area"
              />
            </div>
            {errors?.selectedDepartment?.type === "required" && (
              <p style={{ color: "red" }}>*This field is required</p>
            )}

            <div>
              <SelectSearch
                {...register("selectedMachine", { required: true })}
                value={selectedMachine}
                // onChange={(e) => setSelectedMachine(e)}
                onChange={(e) => selectedMachineHandler(e)}
                options={machineOption}
                search
                filterOptions={fuzzySearch}
                placeholder="Select your Machine"
                id="machineTypeId"
              />
            </div>
            {errors?.selectedMachine?.type === "required" && (
              <p style={{ color: "red" }}>*This field is required</p>
            )}

            <div>
              <SelectSearch
                {...register("hfound", { required: true })}
                value={hfound}
                onChange={(e) => setHfoundHanlde(e)}
                options={systemOption}
                search
                filterOptions={fuzzySearch}
                placeholder="Select your System"
                id="hFoundId"
              />
            </div>
            {errors?.hfound?.type === "required" && (
              <p style={{ color: "red" }}> *This field is required</p>
            )}
            {/* <div>
              <SelectSearch
                {...register("priority", { required: true })}
                value={priority}
                onChange={(e) => setPriority(e)}
                options={[
                  { name: "High", value: "High" },
                  { name: "Medium", value: "Medium" },
                  { name: "Low", value: "Low" },
                ]}
                search
                filterOptions={fuzzySearch}
                placeholder="Select your Defect Priority"
                id="priorityId"
              />
            </div>
            {errors?.priority?.type === "required" && (
              <p style={{ color: "red" }}>*This field is required</p>
            )} */}

            <div>
              <SelectSearch
                {...register("SelectedAnamoly", { required: true })}
                value={SelectAnamoly}
                onChange={handleChange}
                options={defectTypeOption}
                search
                filterOptions={fuzzySearch}
                placeholder="Select your Defect Type"
                id="selectedAnomalyId"
              />
            </div>
            {errors?.SelectedAnamoly?.type === "required" && (
              <p style={{ color: "red" }}>*This field is required</p>
            )}
            {/* <div>
              <SelectSearch
                {...register("DefectStat", { required: true })}
                value={DefactStat}
                onChange={handleChangeStatus}
                options={[
                  { name: "Open", value: "Open" },
                  { name: "Solved", value: "Solved" },
                ]}
                search
                filterOptions={fuzzySearch}
                placeholder="Select your Defect Status"
                id="defectStatId"
              />
            </div>
            {errors?.DefectStat?.type === "required" && (
              <p style={{ color: "red" }}> *This field is required</p>
            )} */}

            <div>
              <SelectSearch
                {...register("defectPart", { required: true })}
                value={defectPart}
                onChange={(e) => setDefectPart(e)}
                options={defectPartOption}
                search
                filterOptions={fuzzySearch}
                placeholder="Select your Defect Part"
                id="defectPartId"
              />
            </div>
            {errors?.defectPart?.type === "required" && (
              <p style={{ color: "red" }}> *This field is required</p>
            )}

            <div>
              <SelectSearch
                {...register("repeatedDefect", { required: true })}
                value={repeatedDefect}
                onChange={(e) => setRepeatedDefect(e)}
                options={[
                  { name: "Yes", value: "Yes" },
                  { name: "No", value: "No" },
                ]}
                search
                filterOptions={fuzzySearch}
                placeholder="Is the Defect Repeated"
                id="repeatedDefectId"
              />
            </div>
            {errors?.repeatedDefect?.type === "required" && (
              <p style={{ color: "red" }}>*This field is required</p>
            )}

            {/* <div>
                            <SelectSearch
                                {...register('wfound', { required: true })}
                                value={wfound}
                                onChange={(e) => setWfound(e)}
                                options={founderOption}
                                search
                                filterOptions={fuzzySearch}
                                placeholder="Who Founded the Defect?"
                                id="wFoundId"

                            />
                        </div>
                        {errors?.wfound?.type === "required" && <p style={{ color: 'red' }}> *This field is required</p>} */}

            <div>
              <SelectSearch
                {...register("Respon", { required: true })}
                value={Respon}
                onChange={(e) => setRespon(e)}
                options={responsibleOption}
                search
                filterOptions={fuzzySearch}
                placeholder="Who is Responsible?"
                id="responId"
              />
            </div>
            {errors?.Respon?.type === "required" && (
              <p style={{ color: "red" }}> *This field is required</p>
            )}
            <div>
              <div className="input-group">
                {/* <label htmlFor='image' className='btn btn-light w-100 text-start'>Upload Photo</label> */}
                {/* <input type="file" className="form-control" id="image" style={{display: 'none'}} /> */}
                <input type="file" accept="image/*" className="form-control" id="image" />
              </div>
            </div>

            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  {...register("TD", { required: true })}
                  required
                  className="w-100 my-0"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Defect Found"
                  value={TD}
                  onChange={setTD}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              {/* {errors?.TD?.type === "required" && <p style={{color:'red'}}> *This field is required</p>} */}
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  {...register("CD", { required: true })}
                  required
                  disableToolbar
                  className="w-100 my-0"
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Expected Close"
                  value={CD}
                  onChange={setCD}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              {/* {errors?.CD?.type === "required" && <p style={{color:'red'}}> *This field is required</p>} */}
            </div>

            <div>
              <input
                id="AP"
                className="action-input"
                {...register("ActionPlan", { required: true })}
                value={ActionPlan}
                onChange={(e) => setActionPlan(e.target.value)}
                placeholder="Action Plan"
                variant="outlined"
                required
              />
            </div>
            {errors?.ActionPlan?.type === "required" && (
              <p style={{ color: "red" }}> *This field is required</p>
            )}
            <div>
              <textarea
                variant="outlined"
                {...register("AmDetail", { required: true })}
                // rows="4"
                rows="1"
                cols="50"
                value={AmDetail}
                onChange={(e) => setAmDetail(e.target.value)}
                placeholder="Defect Detail"
              ></textarea>

              {errors?.AmDetail?.type === "required" && (
                <p style={{ color: "red" }}> *This field is required</p>
              )}
            </div>

            <div className="test-TwoBtn">
              <div>
                {isDisabled ? (
                  <Button
                    style={{
                      background:
                        "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      // border: "2px solid black",
                      padding: "8px 8px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "white",
                      margin: "0",
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={onSubmit1}
                    className={classes.submit}
                    endIcon={<SendIcon />}
                    disabled
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    style={{
                      background:
                        "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      // border: "2px solid black",
                      // padding: "8px 8px",
                      padding: "8px 8px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "white",
                      margin: "0",
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={onSubmit1}
                    className={classes.submit}
                    endIcon={<SendIcon />}
                  >
                    Save
                  </Button>
                )}
              </div>
              <div>
                <Button
                  style={{
                    background: "linear-gradient(45deg, #FE6B8B 30%, red 90%)",
                    // border: "2px solid black",
                    padding: "8px 8px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "white",
                    margin: "0",
                  }}
                  variant="contained"
                  color="secondary"
                  onClick={handleCloseForm}
                  className={classes.submit}
                  endIcon={<SendIcon />}
                >
                  Close
                </Button>
              </div>
            </div>
          </Form>
        </Dialog>
        {/* Full form Dialog */}
      </div>
      <div className="w-100">

        {userRole === 'Admin' ? (
          loading ? (
            <div className="skeletonAdjust">
              <SkeletonTheme color="#A1CDE4" highlightColor="#039BE5">
                <Skeleton count={8} duration={1} />
              </SkeletonTheme>
            </div>
          ) : (
            <div>
              <MaterialTable
                icons={tableIcons}
                style={{
                  marginTop: "10px",
                  whiteSpace: "nowrap",
                  // maxWidth: "150%",
                  tableLayout: "fixed",
                }}
                title="Defects Report"
                columns={columns}
                data={tableDATA.data}
                // console.log('kkkkkkkkkkkkkkkkkkk', rowData)
                // return (
                // detailPanel={rowData => (
                //     <div
                //         className='col-sm d-flex justify-content-start align-items-center'
                //     // onClick={handleOpenModal}
                //     >

                //     </div>

                // )}
                // )
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...tableDATA.data];
                        const index = oldData.id;

                        // console.log(index)
                        dataUpdate[index] = newData;
                        // console.log(newData)
                        // console.log(newData.machine)
                        axios
                          .post(
                            "https://defectbe.pakistanautomation.com.pk/api/defects/updateDefect",
                            newData
                          )
                          .then((res) => {
                            if (res.data.results === "Success") {
                              console.log("updatedSuccessfully");
                              defectData();
                              toast.success("Update successfully !");
                            // } else {
                            //   console.log(res.data.results);
                            //   console.log(newData);
                            //   toast.error("Error !");
                             }
                          }).catch((error)=>{
                            // error from catch
                            console.log("error from catch ==>", error);
                            if(error.message==="Network Error"){
                              console.log("i am here");
                              toast.error("Server Error!");
                              setIsDisabled(false)
                              return;
                    
                            }
                            else if(error.response.status === 404){
                              console.log("i am network error",error.message);
                              toast.error("Please Check your Internet!");
                              setIsDisabled(false)
                              return;
                    
                            }
                           
                            else {
                              toast.error("Error !");
                              setIsDisabled(false)
                            }
                           
                          })
                        resolve();
                      }, 1500);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...tableDATA.data];
                        const index = oldData.id;
                        // console.log(index)
                        // console.log(oldData);
                        // dataDelete.splice(index, 1);
                        // setTableDATA([...dataDelete]);
                        // console.log(index)
                        axios
                          .post(
                            "https://defectbe.pakistanautomation.com.pk/api/defects/deleteDefect",
                            { id: index }
                          )
                          .then((res) => {
                            if (res.data.results === "Success") {
                              console.log("deletedSuccessfully");
                              defectData();
                              toast.info("Delete successfully !");
                              resolve();
                             } 
                             //else {
                            //   console.log(res.data.results);
                            //   toast.error("Error !");
                            // }
                          }).catch((error)=>{
                            // error from catch
                            console.log("error from catch ==>", error);
                            if(error.message==="Network Error"){
                              console.log("i am here");
                              toast.error("Server Error!");
                              setIsDisabled(false)
                              return;
                    
                            }
                            else if(error.response.status === 404){
                              console.log("i am internet error",error.message);
                              toast.error("Please Check your Internet!");
                              setIsDisabled(false)
                              return;
                    
                            }
                            
                            else {
                              toast.error("Error !");
                            }
                           
                          })
                      }, 1500);
                    }),
                }}
                localization={{
                  toolbar: {
                    exportCSVName: "Export Excel",
                    exportPDFName: "",
                  },
                }}
                options={{
                  filtering: true,
                  // exportButton: true,
                  exportButton: {
                    csv: true,
                    pdf: false,
                  },
                  // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],

                  headerStyle: {
                    backgroundColor: "#2E3B55",
                    color: "white",
                  },
                }}
                actions={[
                  {
                    // icon: 'Image',
                    // tooltip: 'Image User',
                    disabled: isImage ? false : true,
                    icon: (rowData) => <ImageIcon style={{ color: "black" }} />,
                    // icon: (rowData) => isImage ?
                    // <ImageIcon style={{ color: 'black' }} />
                    // :
                    // <CircularProgress /> ,
                    // tooltip: "Defect Image...",
                    onClick: isImage
                      ? (event, rowData) => handleOpenModal(rowData)
                      : "",
                    // onClick: (event, rowData) => handleOpenModal(rowData)
                  },
                ]}
                options={{
                  filtering: true,
                  exportButton: true,
                  pageSizeOptions: [
                    5,
                    10,
                    20,
                    { value: tableDATA.data.length, label: "All" },
                  ],

                  headerStyle: {
                    backgroundColor: "#2E3B55",
                    color: "white",
                  },
                }}
              />
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <img
                      width="100%"
                      height="100%"
                      // className='i-frame'
                      src={imageforModal}
                    />
                  </Typography>
                </Box>
              </Modal>
            </div>
          )
        ) : loading ? (
          <div className="skeletonAdjust">
            <SkeletonTheme color="#A1CDE4" highlightColor="#039BE5">
              <Skeleton count={8} duration={1} />
            </SkeletonTheme>
          </div>
        ) : null}

        {
          userRole === 'User' ?
            (
              <div>
                <MaterialTable
                  icons={tableIcons}
                  className="container"
                  style={{
                    marginTop: "10px",
                    whiteSpace: "nowrap",
                    maxWidth: "150%",
                    tableLayout: "fixed",
                  }}
                  actions={[
                    {
                      disabled: true,
                      // icon: "edit",
                      icon: <EditIcon />,
                      // icon: (rowData) =>  <EditIcon style={{ color: 'black' }} />,
                      tooltip: "Edit Team",
                      onClick: (event, rowData) => {
                        // Do save operation
                      },
                    },
                    {
                      disabled: true,
                      // icon: "delete",
                      icon: () => <DeleteIcon style={{ color: "black" }} />,
                      tooltip: "Delete Team",
                      onClick: (event, rowData) => {
                        // Do save operation
                      },
                    },
                  ]}
                  title="Defects"
                  columns={columns}
                  data={feedbackDataIndividual.data}
                  actions={[
                    {
                      // icon: 'Image',
                      // tooltip: 'Image User',
                      disabled: isImage ? false : true,
                      icon: (rowData) => <ImageIcon style={{ color: "black" }} />,
                      // icon: (rowData) => isImage ?
                      // <ImageIcon style={{ color: 'black' }} />
                      // :
                      // <CircularProgress /> ,
                      // tooltip: "Defect Image...",
                      onClick: isImage
                        ? (event, rowData) => handleOpenModal(rowData)
                        : "",
                      // onClick: (event, rowData) => handleOpenModal(rowData)
                    },
                  ]}
                  options={{
                    filtering: true,
                    exportButton: true,
                    // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],

                    headerStyle: {
                      backgroundColor: "#2E3B55",
                      color: "white",
                    },
                  }}
                />
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      <img
                        width="100%"
                        height="100%"
                        // className='i-frame'
                        src={imageforModal}
                      />
                    </Typography>
                  </Box>
                </Modal>
              </div>
            ) : null
        }

        {userRole === 'SuperUser' ? (
          loading ? (
            <div className="skeletonAdjust">
              <SkeletonTheme color="#A1CDE4" highlightColor="#039BE5">
                <Skeleton count={8} duration={1} />
              </SkeletonTheme>
            </div>
          ) : (
            <div>
              <MaterialTable
                icons={tableIcons}
                style={{
                  marginTop: "10px",
                  whiteSpace: "nowrap",
                  // maxWidth: "150%",
                  tableLayout: "fixed",
                }}
                title="Defects Report"
                columns={columns}
                data={tableDATA.data}
                // console.log('kkkkkkkkkkkkkkkkkkk', rowData)
                // return (
                // detailPanel={rowData => (
                //     <div
                //         className='col-sm d-flex justify-content-start align-items-center'
                //     // onClick={handleOpenModal}
                //     >

                //     </div>

                // )}
                // )
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...tableDATA.data];
                        const index = oldData.id;

                        // console.log(index)
                        dataUpdate[index] = newData;
                        // console.log(newData)
                        // console.log(newData.machine)
                        axios
                          .post(
                            "https://defectbe.pakistanautomation.com.pk/api/defects/updateDefect",
                            newData
                          )
                          .then((res) => {
                            if (res.data.results === "Success") {
                              console.log("updatedSuccessfully");
                              defectData();
                              toast.success("Update successfully !");
                            } else {
                              console.log(res.data.results);
                              console.log(newData);
                              toast.error("Error !");
                            }
                          }).catch((error)=>{
                            // error from catch
                            console.log("error from catch ==>", error);
                            if(error.message==="Network Error"){
                              console.log("i am here");
                              toast.error("Server Error!");
                              setIsDisabled(false)
                              return;
                    
                            }
                            
                           
                            else {
                              toast.error("Error !");
                              return;
                            }
                           
                          })
                        resolve();
                      }, 1500);
                    }),
                  // onRowDelete: (oldData) =>
                  //   new Promise((resolve, reject) => {
                  //     setTimeout(() => {
                  //       const dataDelete = [...tableDATA.data];
                  //       const index = oldData.id;
                  //       // console.log(index)
                  //       // console.log(oldData);
                  //       // dataDelete.splice(index, 1);
                  //       // setTableDATA([...dataDelete]);
                  //       // console.log(index)
                  //       axios
                  //         .post(
                  //           "https://defectbe.pakistanautomation.com.pk/api/defects/deleteDefect",
                  //           { id: index }
                  //         )
                  //         .then((res) => {
                  //           if (res.data.results === "Success") {
                  //             console.log("deletedSuccessfully");
                  //             defectData();
                  //             toast.info("Delete successfully !");
                  //             resolve();
                  //           } else {
                  //             console.log(res.data.results);
                  //             toast.error("Error !");
                  //           }
                  //         });
                  //     }, 1500);
                  //   }),
                }}
                localization={{
                  toolbar: {
                    exportCSVName: "Export Excel",
                    exportPDFName: "",
                  },
                }}
                options={{
                  filtering: true,
                  // exportButton: true,
                  exportButton: {
                    csv: true,
                    pdf: false,
                  },
                  // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],

                  headerStyle: {
                    backgroundColor: "#2E3B55",
                    color: "white",
                  },
                }}
                actions={[
                  {
                    // icon: 'Image',
                    // tooltip: 'Image User',
                    disabled: isImage ? false : true,
                    icon: (rowData) => <ImageIcon style={{ color: "black" }} />,
                    // icon: (rowData) => isImage ?
                    // <ImageIcon style={{ color: 'black' }} />
                    // :
                    // <CircularProgress /> ,
                    // tooltip: "Defect Image...",
                    onClick: isImage
                      ? (event, rowData) => handleOpenModal(rowData)
                      : "",
                    // onClick: (event, rowData) => handleOpenModal(rowData)
                  },
                ]}
                options={{
                  filtering: true,
                  exportButton: true,
                  pageSizeOptions: [
                    5,
                    10,
                    20,
                    { value: tableDATA.data.length, label: "All" },
                  ],

                  headerStyle: {
                    backgroundColor: "#2E3B55",
                    color: "white",
                  },
                }}
              />
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <img
                      width="100%"
                      height="100%"
                      // className='i-frame'
                      src={imageforModal}
                    />
                  </Typography>
                </Box>
              </Modal>
            </div>
          )
        ) : null}


      </div>
















    </div>




  );
}
